import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
function getCurrentLang() {
  return localStorage.getItem("i18nextLng") || "en";
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          languageLabel: "English",
          aboutHeader: "Speedy, fast and easy manage your products.",
          aboutDescription:
            "Beeshelf provides a comprehensive solution for efficiently managing and consigning goods. With a user-friendly and easy-to-use interface, users can track the status of their orders in real-time.",
          aboutBenefit01: "User-friendly interface",
          aboutBenefit02: "Provides reliable warehouses",
          aboutBenefit03: "Easily manage orders",
          aboutBenefit04: "Easily register for consignment",
          Getstarted: "Get started",
          Viewpreview: "View preview",
          SCROLLTOEXPLORE: "SCROLL TO EXPLORE",
          About: "About",
          Service: "Service",
          Feature: "Feature",
          Customers: "Customers",
          Contact: "Contact",
          SignIn: "Sign In",
          SignUp: "Sign Up",
          Trustedbybusinesses: "Trusted by businesses",
          Agiledeliveryteam: "Agile delivery team",
          Happycustomer: "Happy customer",
          Overallrating: "Overall rating",
          Whychooseus: "Why choose us",
          Getthebestpackageforyourbusiness:
            "Get the best package for your business",
          servicesDesciption:
            "We work systematically to integrate corporate responsibility in our core business and make our expertise available for the benefits of the societies where we operate",
          servicesBenefit01:
            "Website and application that are easy to use and easy for users to understand",
          servicesBenefit02:
            "We have application to support shipper and clients to track their orders",
          servicesBenefit03:
            "Perfect for large sites or agencies that manage many clients, warehouse, inventory, products",
          Warehouses: "Warehouses",
          Filters: "Filters",
          Name: "Name",
          Province: "Province",
          Status: "Status",
          Capacity: "Capacity",
          Location: "Location",
          MaxWeight: "Max weight",
          Weight: "Weight",
          Price: "Price",
          Expiredon: "Expired on",
          days: "days",
          WarehouseInformation: "Warehouse Information",
          InventoriesInTheWarehouse: "Inventories in the Warehouse",
          Dashboard: "Dashboard",
          Inventory: "Inventory",
          Product: "Product",
          Order: "Order",
          Request: "Request",
          Warehouse: "Warehouse",
          Employee: "Employee",
          CreateBatch: "Create Batch",
          AssignOrder: "Assign Order",
          OrderManage: "Order Management",
          RequestManageRequest: "Request Management",
          UserProfile: "User Profile",
          PersonalDetails: "Personal details",
          PhoneNumber: "Phone number:",
          Email: "Email:",

          BusinessDetails: "Business details",
          BusinessName: "Business name:",
          CategoryName: "Category name:",
          OcopCategoryName: "Ocop category name:",
          Logout: "Logout",
          Partner: "Partner",
          Staff: "Staff",
          Manager: "Manager",
          TotalLotsImported: "Total Lots Imported",
          Origin: "Origin",
          Category: "Category",
          CreateDate: "Create Date",
          ProductDetail: "Product Detail",
          CreateRequest: "Create Request",
          Edit: "Edit",
          BoughtDate: "Bought Date",
          ExpirationDate: "Expiration Date",
          Extend: "Extend",
          InventoryName: "Inventory Name",
          InventoryInformation: "Inventory Information",
          ExtendingInventory: "Extending Inventory",
          Month: "Month",
          Months: "Months",
          Year: "Year",
          Years: "Years",
          ExpectExpirationDate: "Expected Expiration Date",
          Amount: "Amount",
          Unit: "Unit",
          Total: "Total",
          Cancel: "Cancel",
          Confirm: "Confirm",
          BuyingInventory: "Buying Inventory",
          Hired: "Hired",
          ChooseInventory: "Choose Inventory",
          RequestName: "Request Name",
          RequestDescription: "Request Description",
          AmountofLot: "Amount of Lot",
          AmountOfProductPerLot: "Amount of Product Per Lot",
          Reset: "Reset",
          SaveAsDraft: "Save as Draft",
          SelectYourHiredInventory: "Select Your Hired Inventory",
          Barcode: "Barcode",
          Image: "Image",
          AddProduct: "Add Product",
          UploadAFilePNGJPGGIFUpTo10MB:
            "Upload a file (PNG, JPG, GIF) up to 10MB",
          AreYouSureWantToDelete: "Are you sure you want to delete",
          Products: "Products",
          ImportExcel: "Import Excel",
          ExportExcel: "Export Excel",
          Search: "Search",
          ChooseUnit: "Choose unit",
          Liter: "Liter",
          Milliliter: "Milliliter",
          Pieces: "Pieces",
          Box: "Box",
          Bottle: "Bottle",
          Package: "Package",
          Carton: "Carton",
          Meter: "Meter",
          Centimeter: "Centimeter",
          SquareMeter: "Square Meter",
          Kilometer: "Kilometer",
          Bag: "Bag",
          Sheet: "Sheet",
          Roll: "Roll",
          Jar: "Jar",
          Pot: "Pot",
          Tablet: "Tablet",
          Can: "Can",
          ImportProductsByExcel: "Import Products by Excel",
          DownloadExcelTemplate: "Download Excel Template",
          UploadFileExcel: "Upload File (Excel)",
          ImportAll: "Import All",
          ClearData: "Clear Data",
          RemoveAllDuplicated: "Remove All Duplicated",
          Save: "Save",
          Imported: "Imported",
          NotImported: "Not Imported",
          TotalProducts: "Total Products",
          NoFileUploaded: "No file uploaded",
          ItemsPerPage: "Items Per Page",
        },
      },
      vi: {
        translation: {
          languageLabel: "Tiếng Việt",
          aboutHeader:
            "Nhanh chóng, tiện lợi và dễ dàng quản lý sản phẩm của bạn.",
          aboutDescription:
            "Beeshelf cung cấp giải pháp toàn diện để quản lý và ký gửi hàng hóa một cách hiệu quả. Với giao diện thân thiện, dễ sử dụng, người dùng có thể quản lý được trạng thái đơn hàng bằng thời gian thật.",
          aboutBenefit01: "Giao diện dễ dùng",
          aboutBenefit02: "Cung cấp các kho hàng uy tín",
          aboutBenefit03: "Dễ dàng quản lý đơn hàng",
          aboutBenefit04: "Dễ dàng đăng ký ký gửi",
          Getstarted: "Bắt đầu",
          Viewpreview: "Xem trước",
          SCROLLTOEXPLORE: "CUỘN ĐỂ KHÁM PHÁ",
          About: "Giới thiệu",
          Service: "Dịch vụ",
          Feature: "Tính năng",
          Customers: "Khách hàng",
          Contact: "Liên hệ",
          SignIn: "Đăng nhập",
          SignUp: "Đăng ký",
          Trustedbybusinesses: "Doanh nghiệp tin tưởng sử dụng",
          Agiledeliveryteam: "Đội ngũ shipper nhanh nhẹn",
          Happycustomer: "Khách hàng hài lòng",
          Overallrating: "Đánh giá tổng thể",
          Ourservice: "Our service",
          Ourservice: "Dịch vụ của chúng tôi",
          Whychooseus: "Tại sao chọn chúng tôi",
          Getthebestpackageforyourbusiness:
            "Nhận gói tốt nhất cho doanh nghiệp của bạn",
          servicesDesciption:
            "Chúng tôi làm việc có hệ thống để tích hợp trách nhiệm doanh nghiệp vào hoạt động kinh doanh cốt lõi và chia sẻ chuyên môn của mình nhằm mang lại lợi ích cho các cộng đồng nơi chúng tôi hoạt động",

          servicesBenefit01:
            "Website và ứng dụng dễ sử dụng và dễ hiểu đối với người dùng",
          servicesBenefit02:
            "Chúng tôi có ứng dụng hỗ trợ shipper và khách hàng theo dõi đơn hàng của họ",
          servicesBenefit03:
            "Hoàn hảo cho các trang web lớn hoặc các đại lý quản lý nhiều khách hàng, kho bãi, hàng tồn kho, và sản phẩm",
          Warehouses: "Kho hàng",
          Filters: "Bộ lọc",
          Name: "Tên",
          Province: "Tỉnh",
          Status: "Trạng thái",
          Capacity: "Sức chứa",
          Location: "Vị trí",
          MaxWeight: "Trọng lượng tối đa",
          Weight: "Trọng lượng",
          Price: "Giá",
          Expiredon: "Hết hạn vào",
          days: "ngày",
          WarehouseInformation: "Thông tin kho hàng",
          InventoriesInTheWarehouse: "Hàng tồn kho trong kho",
          Dashboard: "Thống kê",
          Inventory: "Kho hàng",
          Product: "Sản phẩm",
          Order: "Đơn hàng",
          Request: "Yêu cầu",
          Warehouse: "Kho hàng",
          Employee: "Nhân viên",
          CreateBatch: "Tạo lô hàng",
          AssignOrder: "Phân công đơn hàng",
          OrderManage: "Quản lý đơn hàng",
          RequestManageRequest: "Quản lý yêu cầu",
          UserProfile: "Hồ sơ người dùng",
          PersonalDetails: "Thông tin cá nhân",
          PhoneNumber: "Số điện thoại:",
          Email: "Email:",

          BusinessDetails: "Thông tin doanh nghiệp",
          BusinessName: "Tên doanh nghiệp:",
          CategoryName: "Tên danh mục:",
          OcopCategoryName: "Tên danh mục OCOP:",
          Logout: "Đăng xuất",
          Partner: "Đối tác",
          Staff: "Nhân viên",
          Manager: "Quản lý",
          TotalLotsImported: "Tổng số lô đã nhập",
          Origin: "Xuất xứ",
          Category: "Danh mục",
          CreateDate: "Ngày tạo",
          ProductDetail: "Chi tiết sản phẩm",
          CreateRequest: "Tạo yêu cầu",
          Edit: "Chỉnh sửa",
          BoughtDate: "Ngày mua",
          ExpirationDate: "Ngày hết hạn",
          Extend: "Gia hạn",
          InventoryName: "Tên kho",
          InventoryInformation: "Thông tin kho",
          ExtendingInventory: "Gia hạn kho",
          Month: "Tháng",
          Months: "Các tháng",
          Year: "Năm",
          Years: "Các năm",
          ExpectExpirationDate: "Ngày hết hạn dự kiến",
          Amount: "Số lượng",
          Unit: "Đơn vị",
          Total: "Tổng cộng",
          Cancel: "Hủy",
          Confirm: "Xác nhận",
          BuyingInventory: "Mua hàng tồn kho",
          Hired: "Đã thuê",
          ChooseInventory: "Chọn kho",
          RequestName: "Tên yêu cầu",
          RequestDescription: "Mô tả yêu cầu",
          AmountofLot: "Số lượng lô",
          AmountOfProductPerLot: "Số lượng sản phẩm mỗi lô",
          Reset: "Đặt lại",
          SaveAsDraft: "Lưu dưới dạng bản nháp",
          SelectYourHiredInventory: "Chọn kho đã thuê của bạn",
          Barcode: "Mã vạch",
          Image: "Hình ảnh",
          AddProduct: "Thêm sản phẩm",
          UploadAFilePNGJPGGIFUpTo10MB:
            "Tải lên tệp (PNG, JPG, GIF) tối đa 10MB",
          AreYouSureWantToDelete: "Bạn có chắc chắn muốn xóa",
          Products: "Sản phẩm",
          ImportExcel: "Nhập Excel",
          ExportExcel: "Xuất Excel",
          Search: "Tìm kiếm",
          ChooseUnit: "Chọn đơn vị",
          Liter: "Lít",
          Milliliter: "Mililit",
          Pieces: "Cái",
          Box: "Hộp",
          Bottle: "Chai",
          Package: "Gói",
          Carton: "Thùng",
          Meter: "Mét",
          Centimeter: "Xăng-ti-mét",
          SquareMeter: "Mét vuông",
          Kilometer: "Kilômét",
          Bag: "Túi",
          Sheet: "Tờ",
          Roll: "Cuộn",
          Jar: "Hũ",
          Pot: "Nồi",
          Tablet: "Viên",
          Can: "Lon",
          ImportProductsByExcel: "Nhập sản phẩm bằng Excel",
          DownloadExcelTemplate: "Tải mẫu Excel",
          UploadFileExcel: "Tải tệp Excel lên",
          ImportAll: "Nhập tất cả",
          ClearData: "Xóa dữ liệu",
          RemoveAllDuplicated: "Xóa tất cả các mục trùng lặp",
          Save: "Lưu",
          Imported: "Đã nhập",
          NotImported: "Chưa nhập",
          TotalProducts: "Tổng số sản phẩm",
          NoFileUploaded: "Chưa tải lên tệp nào",
          ItemsPerPage: "Số mục mỗi trang",
        },
      },
      ja: {
        translation: {
          languageLabel: "日本語",
          aboutHeader: "迅速で簡単、そして手軽に製品を管理しましょう。",
          aboutDescription:
            "Beeshelf は、効率的に商品を管理および委託するための包括的なソリューションを提供します。使いやすく親しみやすいインターフェースにより、ユーザーはリアルタイムで注文状況を追跡することができます。",
          aboutBenefit01: "使いやすいインターフェース",
          aboutBenefit02: "信頼できる倉庫を提供",
          aboutBenefit03: "注文を簡単に管理",
          aboutBenefit04: "簡単に委託登録",
          Getstarted: "始める",
          Viewpreview: "プレビューを見る",
          SCROLLTOEXPLORE: "スクロールして探索",
          About: "概要",
          Service: "サービス",
          Feature: "特徴",
          Customers: "お客様",
          Contact: "お問い合わせ",
          SignIn: "ログイン",
          SignUp: "登録",
          Trustedbybusinesses: "企業からの信頼を得ています",
          Agiledeliveryteam: "機敏な配達チーム",
          Happycustomer: "満足したお客様",
          Overallrating: "総合評価",
          Ourservice: "私たちのサービス",
          Whychooseus: "なぜ私たちを選ぶのか",
          Getthebestpackageforyourbusiness:
            "最適なパッケージを手に入れましょうあなたのビジネス",
          servicesDesciption:
            "私たちは、コアビジネスに企業責任を体系的に統合し、私たちが活動する地域社会の利益のために専門知識を提供するよう努めています",

          servicesBenefit01:
            "使いやすく、ユーザーにとってわかりやすいウェブサイトとアプリケーション",
          servicesBenefit02:
            "配達員と顧客が注文を追跡できるアプリケーションを提供しています",
          servicesBenefit03:
            "多くの顧客、倉庫、在庫、製品を管理する大規模なサイトや代理店に最適です",
          Warehouses: "倉庫",
          Filters: "フィルター",
          Name: "名前",
          Province: "都道府県",
          Status: "ステータス",
          Capacity: "容量",
          Location: "場所",
          MaxWeight: "最大重量",
          Weight: "重量",
          Price: "価格",
          Expiredon: "有効期限",
          days: "日数",
          WarehouseInformation: "倉庫情報",
          InventoriesInTheWarehouse: "倉庫内の在庫",
          Dashboard: "周囲",
          Inventory: "在庫",
          Product: "製品",
          Order: "注文",
          Request: "リクエスト",
          Warehouse: "倉庫",
          Employee: "従業員",
          CreateBatch: "バッチ作成",
          AssignOrder: "注文割り当て",
          OrderManage: "注文管理",
          RequestManageRequest: "リクエスト管理",
          UserProfile: "ユーザープロファイル",
          PersonalDetails: "個人情報",
          PhoneNumber: "電話番号:",
          Email: "メールアドレス:",

          BusinessDetails: "事業の詳細",
          BusinessName: "事業名:",
          CategoryName: "カテゴリー名:",
          OcopCategoryName: "OCOPカテゴリー名:",
          Logout: "ログアウト",
          Partner: "パートナー",
          Staff: "スタッフ",
          Manager: "マネージャー",
          TotalLotsImported: "輸入ロット数",
          Origin: "原産地",
          Category: "カテゴリー",
          CreateDate: "作成日",
          ProductDetail: "製品詳細",
          CreateRequest: "リクエスト作成",
          Edit: "編集",
          BoughtDate: "購入日",
          ExpirationDate: "有効期限",
          Extend: "延長",
          InventoryName: "在庫名",
          InventoryInformation: "在庫情報",
          ExtendingInventory: "在庫延長",
          Month: "月",
          Months: "月数",
          Year: "年",
          Years: "年数",
          ExpectExpirationDate: "予想有効期限",
          Amount: "数量",
          Unit: "単位",
          Total: "合計",
          Cancel: "キャンセル",
          Confirm: "確認",
          BuyingInventory: "在庫購入",
          Hired: "雇用済み",
          ChooseInventory: "在庫を選択",
          RequestName: "リクエスト名",
          RequestDescription: "リクエストの説明",
          AmountofLot: "ロット数",
          AmountOfProductPerLot: "1ロットあたりの製品数",
          Reset: "リセット",
          SaveAsDraft: "下書きとして保存",
          SelectYourHiredInventory: "雇用済みの在庫を選択",
          Barcode: "バーコード",
          Image: "画像",
          AddProduct: "製品を追加",
          UploadAFilePNGJPGGIFUpTo10MB:
            "ファイルをアップロード (PNG, JPG, GIF) 最大10MB",
          AreYouSureWantToDelete: "削除してもよろしいですか",
          Products: "製品",
          ImportExcel: "Excelをインポート",
          ExportExcel: "Excelをエクスポート",
          Search: "検索",
          ChooseUnit: "単位を選択",
          Liter: "リットル",
          Milliliter: "ミリリットル",
          Pieces: "個",
          Box: "箱",
          Bottle: "ボトル",
          Package: "パッケージ",
          Carton: "カートン",
          Meter: "メートル",
          Centimeter: "センチメートル",
          SquareMeter: "平方メートル",
          Kilometer: "キロメートル",
          Bag: "袋",
          Sheet: "シート",
          Roll: "ロール",
          Jar: "瓶",
          Pot: "鍋",
          Tablet: "錠剤",
          Can: "缶",
          ImportProductsByExcel: "Excelで製品をインポート",
          DownloadExcelTemplate: "Excelテンプレートをダウンロード",
          UploadFileExcel: "ファイルをアップロード (Excel)",
          ImportAll: "すべてインポート",
          ClearData: "データをクリア",
          RemoveAllDuplicated: "すべての重複を削除",
          Save: "保存",
          Imported: "インポート済み",
          NotImported: "未インポート",
          TotalProducts: "製品の合計",
          NoFileUploaded: "ファイルがアップロードされていません",
          ItemsPerPage: "ページあたりの項目数",
        },
      },
    },
  });

export default i18n;
